import '@babel/polyfill';
import './assets/less/bootstrap.less';
import 'font-awesome/css/font-awesome.css';
import 'katex/dist/katex.min.css';
import './styles/fonts.css';
import './styles/client.css';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import VueClip from 'vue-clip';
import { createPinia, PiniaVuePlugin } from 'pinia';

import App from './App';

import userModel from './models/user.js';

const onlyForUsers = (to, from, next) => userModel.isRegisteredUser() ? next() : next(false);

const routeOptions = [
  {
    path: '/:lang?/p/:id',
    name: 'ProjectRoute'
  },
  {
    path: '/:lang?/p/:id/*',
    name: 'SectionRoute'
  },
  {
    path: '/:lang?/t/:id',
    name: 'ProblemSetRoute'
  },
  {
    path: '/:lang?/s/*',
    name: 'NewspageRoute'
  },
  {
    path: '/:lang?/recovery/:key',
    name: 'RecoveryRoute'
  },
  {
    path: '/:lang?/register',
    name: 'RegistrationRoute'
  },
  {
    path: '/confirm/:key',
    name: 'ConfirmRoute'
  },
  {
    path: '/personal',
    name: 'PersonalSectionsRoute',
    beforeEnter: onlyForUsers
  },
  {
    path: '/profile',
    name: 'ProfileRoute',
    beforeEnter: onlyForUsers,
    children: [
      ...window._STATE.fieldCategories.map(category => (category.slug === '' ? {
        path: '',
        name: 'ProfilePersonalRoute',
        component: () => import(/* webpackChunkName: "[request]" */ `./components/profile-subpages/ProfilePersonalRoute`),
      } : {
        path: category.slug,
        name: `ProfileAdditionalRoute-${category.slug}`,
        props: { category },
        component: () => import(/* webpackChunkName: "[request]" */ `./components/profile-subpages/ProfileAdditionalRoute`),
      })),
      {
        path: 'password',
        name: 'ProfilePasswordRoute',
        component: () => import(/* webpackChunkName: "[request]" */ `./components/profile-subpages/ProfilePasswordRoute`),
      },
      {
        path: 'indoors',
        name: 'ProfileStreamsRoute',
        component: () => import(/* webpackChunkName: "[request]" */ `./components/profile-subpages/ProfileStreamsRoute`),
      },
      {
        path: 'notifications',
        name: 'ProfileNotificationsRoute',
        component: () => import(/* webpackChunkName: "[request]" */ `./components/profile-subpages/ProfileNotificationsRoute`),
      },
    ],
  },
  {
    path: '/indoors/:id',
    name: 'IndoorsRoute'
  },
  {
    path: '/indoors/participants/all',
    name: 'IndoorsAllParticipantsRoute'
  },
  {
    path: '/indoors/:id/candidates',
    name: 'IndoorsCandidatesRoute'
  },
  {
    path: '/:lang?',
    name: 'ProjectsList'
  },
];

const routes = routeOptions.map(route => ({
  ...route,
  component: () => import(/* webpackChunkName: "[request]" */ `./components/${route.name}`)
}));

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash};
    }
    if (to.name === 'SectionRoute') {
      // Грязный хак определения мобильной вёрстки: в ней блок с секцией имеет нулевой сдвиг
      const sectionElement = document.getElementById('main-content');
      const isMobile = sectionElement && sectionElement.offsetLeft === 0;
      return isMobile ? {selector: '#main-content'} : {x: 0, y: 0};
    }
    return {x: 0, y: 0};
  }
});

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(VueClip);

import VueYandexMetrika from 'vue-yandex-metrika';
import VueGtag from 'vue-gtag';
import VueGtm from "vue-gtm";

if (window._STATE.analytics) {
  if (window._STATE.analytics.metrika && window._STATE.analytics.metrika.id) {
    Vue.use(VueYandexMetrika, {
      ...window._STATE.analytics.metrika,
      router,
      env: process.env.NODE_ENV
    });
  }

  if (window._STATE.analytics.gtag && window._STATE.analytics.gtag.id) {
    Vue.use(VueGtag, window._STATE.analytics.gtag, router);
  }

  if (window._STATE.analytics.gtm && window._STATE.analytics.gtm.id) {
    Vue.use(VueGtm, {
      ...window._STATE.analytics.gtm,
      vueRouter: router
    });
  }
}

import 'chart.js';
import 'hchs-vue-charts';
import './helpers/sticky';
import moment from "moment";

import {useProfileStore} from "./stores/profile";
import {useProctoringStore} from "./stores/proctoring";

Vue.use(window.VueCharts);
Vue.config.lang = window._STATE.lang || 'ru';
moment.locale(Vue.config.lang);
const i18n = new VueI18n({
  locale: window._STATE.lang,
  messages: {[window._STATE.lang]: window._STATE.translations},
  silentTranslationWarn: true,
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const proctoringStore = useProctoringStore(pinia);
const profileStore = useProfileStore(pinia);
profileStore.profile = window._STATE.user;

Promise.all([
  profileStore.loadFieldTypes(),
  proctoringStore.init(),
]).then(() => {
  new Vue({
    router,
    i18n,
    pinia,
    el: '#app',
    render: h => h(App),
  });
});
